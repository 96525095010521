import { defineMessage } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import type {
  FilterDefinitions,
  Aggregation,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import { rankings } from 'match-sorter';
import useSiteConfig from '@bottlebooks/gatsby-theme-event/src/components/useSiteConfig';
import eventMappings from './eventMappings';

// TODO generate this automatically from the fragment.
interface Exhibitor {
  event?: { name?: string } | null;
  region?: string | null;
  city?: string | null;
  countryName?: string | null;
  isSeekingDistribution: boolean | null;
  profile?: {
    distributionInCountries?: (string | null)[] | null;
    seekingDistributionInCountries?: (string | null)[] | null;
  } | null;
  stand?: { name?: string | null } | null;
}

export default function useExhibitorFilters(): FilterDefinitions<Exhibitor> {
  const { showDistributionDetails } = useSiteConfig();
  return {
    aggregations: {
      region: {
        title: defineMessage({ message: 'Regions' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        get: (exhibitor) => exhibitor.event?.eventId,
        translations: eventMappings,
      },
      highlights: {
        title: defineMessage({ message: 'Highlights' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        get: 'offeringTypes',
        translations: {
          videos: defineMessage({ message: 'Videos' }),
          onlineShop: defineMessage({ message: 'Online shop' }),
          imageGallery: defineMessage({ message: 'Image gallery' }),
        },
      },
      countryName: {
        title: defineMessage({ message: 'Countries' }),
        size: 100,
        conjunction: false,
        // Remove null values
        get: (exhibitor) => exhibitor.countryName || [],
      },
      ...getDistributionFilters(showDistributionDetails),
      priceRanges: {
        title: defineMessage({ message: 'Price ranges' }),
        size: 100,
        conjunction: false,
      },
    },

    sortings: {
      byStandName: {
        title: defineMessage({ message: 'Table number' }),
        field: [sortBy.standName, 'profile.sortName', 'name'],
        order: 'asc',
      },
      // byDataCompleteness: {
      //   title: defineMessage({ message: 'By data completeness' }),
      //   field: [
      //     sortBy.standName,
      //     sortBy.dataCompleteness,
      //     'profile.sortName',
      //     'name',
      //   ],
      //   order: 'asc',
      // },
      byExhibitorName: {
        title: defineMessage({ message: 'Exhibitor name' }),
        field: ['profile.sortName', 'name'],
        order: 'asc',
      },
    },
    search: {
      threshold: rankings.WORD_STARTS_WITH,
      keys: [
        { key: 'name', threshold: rankings.CONTAINS },
        (exhibitor) =>
          `${exhibitor.countryName || ''} ${exhibitor.region || ''} ${
            exhibitor.city || ''
          }`.trim(),
        (exhibitor) => exhibitor?.stand?.name?.replace(/[()]/g, '') || '',
      ],
    },
  };
}

function getDistributionFilters(
  showDistributionDetails: 'inMarket' | 'global' | 'all' | 'none'
): Record<string, Aggregation<Exhibitor>> {
  switch (showDistributionDetails) {
    case 'inMarket':
      return { isSeekingDistribution };

    case 'global':
      return {
        distributionInCountries,
        seekingDistributionInCountries,
      };

    case 'all':
      return {
        isSeekingDistribution,
        distributionInCountries,
        seekingDistributionInCountries,
      };

    case 'none':
    default:
      return {};
  }
}

const distributionInCountries: Aggregation<Exhibitor> = {
  title: defineMessage({ message: 'Distribution in countries' }),
  size: 200,
  conjunction: true,
  multiSelect: true,
  get: (exhibitor) =>
    (exhibitor.profile?.distributionInCountries?.filter(Boolean) as string[]) ||
    [],
};

const seekingDistributionInCountries: Aggregation<Exhibitor> = {
  title: defineMessage({ message: 'Seeking distribution' }),
  size: 200,
  conjunction: true,
  multiSelect: true,
  get: (exhibitor) =>
    (exhibitor.profile?.seekingDistributionInCountries?.filter(
      Boolean
    ) as string[]) || [],
};

const isSeekingDistribution: Aggregation<Exhibitor> = {
  title: defineMessage({ message: 'Distribution in Market' }),
  size: 100,
  conjunction: false,
  get: (exhibitor) => String(exhibitor.isSeekingDistribution || false),
  translations: {
    true: defineMessage({ message: 'Seeking Distribution' }),
    false: defineMessage({ message: 'Has Distribution' }),
  },
};

const completeness = {
  ORGANIZER_REVIEWED: 1,
  STEP_COMPLETED: 2,
  STEP_PAYMENT: 2,
  STEP_REVIEW: 3,
};

// Sort helper functions
const sortBy = {
  standName: (exhibitor: Exhibitor) => {
    return (
      (exhibitor?.stand?.name
        ?.split(/[\s,-]+/)
        .map((segment) =>
          segment === 'Tisch'
            ? undefined
            : Number(segment)
            ? segment.padStart(3, '0')
            : segment
        )
        .filter(Boolean) as string[]) || []
    );
  },
  dataCompleteness: (exhibitor) =>
    completeness[exhibitor?.registrationStatus] -
    (exhibitor?.registeredProducts?.totalCount > 0 ? 1 : 0),
};

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment exhibitorMultiEventFilter on Exhibitor {
    event {
      eventId
      name
    }
  }
  fragment exhibitorFilter_Exhibitor on Exhibitor {
    isSeekingDistribution
  }
  fragment exhibitorFilters on Bottlebooks_SingleRegistration {
    companyId
    stand {
      name
    }
    registrationStatus
    registeredProducts {
      totalCount
    }
    profile {
      name
      sortName
      countryName: country(format: LOCALIZED)
      region
      city
      distributionInCountries
      seekingDistributionInCountries
      # priceRanges
      sortName
    }
  }
  #fragment exhibitorFilters_RegisteredBrand on Bottlebooks_RegisteredBrand {
  #  isSeekingDistribution
  #}
`;
