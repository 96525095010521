export default {
  // Copenhagen - California
  '616ed6dcdf1fb00f24dfe6e8': 'California',
  // Copenhagen: Washington
  '619b5f92e85c6814e78fd73a': 'Washington',

  // Paris - California
  '617804c576ad850e0385c0e3': 'California',
  // Paris - Oregon
  '619be49c15aa2a0f1e9a488e': 'Oregon',
};
