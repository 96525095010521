// @ts-check
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Flex } from '@bottlebooks/gatsby-theme-base';
import BottleVariant from '@bottlebooks/gatsby-theme-event/src/components/Product/BottleVariant';
import Section from '@bottlebooks/gatsby-theme-event/src/components/Section/Section';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { jsx } from 'theme-ui';
function hasContent(product) {
  if (
    product?.bottleVariants?.some((bottleVariant) =>
      BottleVariant.hasContent(bottleVariant)
    )
  ) {
    return true;
  }
  return false;
}

/**
 * This really should be done like this at all.
 * The purpose of this is to display sustainability-relevant information, not bottle details.
 * However, due to time constraints, we're putting it here for now.
 */
/**
 * Gets the unique BottleVariants, deferring to BottleVariant.
 * This is because only the BottleVariants component knows which fields are used.
 * We have to do this because the bottle variants also contain other data that might be used elsewhere, such as the internalId.
 * @template {Parameters<BottleVariant.getContent>[0]} BottleVariant
 * @param {BottleVariant[]} variants
 * @returns
 */
function getUniqueVariants(variants) {
  return uniqWith(variants, (value, other) =>
    isEqual(BottleVariant.getContent(value), BottleVariant.getContent(other))
  );
}

export default function ProductBottleVariants({ product, ...rest }) {
  if (!hasContent(product)) return null;
  return (
    <Section title={'Bottle weight (empty)'} {...rest}>
      <Flex gap={3} wrap="wrap">
        {getUniqueVariants(product.bottleVariants).map(
          (bottleVariant, index) => (
            // We only have the index as an identifier - it's ok here because the order can't be changed.
            <BottleVariant key={index} bottleVariant={bottleVariant} />
          )
        )}
      </Flex>
    </Section>
  );
}

export const fragment = graphql`
  fragment bb_ProductBottleVariants on Bottlebooks_Product {
    bottleVariants {
      ...bb_BottleVariant
    }
  }
`;

ProductBottleVariants.fragment = gql`
  fragment ProductBottleVariants on Product {
    bottleVariants {
      ...BottleVariant
    }
  }
  ${BottleVariant.fragment}
`;
