/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  ChevronDownIcon,
  Flex,
  Label,
  Menu,
  MenuButton,
  MenuLink,
  MenuList,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';

export default function MultisiteSwitcher({ ...rest }) {
  const result = useStaticQuery(query);
  const collectionId = result?.collection?.collectionId;
  const sites = result?.allSanitySite?.nodes;
  const site = sites?.find((site) => site.collectionId === collectionId);
  const otherSites = site?.sites?.filter(
    (site) => site.collectionId !== collectionId
  );
  if (otherSites?.length > 0)
    return (
      <Menu>
        <MenuButton variant="text" {...rest}>
          <Flex align="center">
            <Box sx={{ textAlign: 'left' }}>
              <Label
                variant="small"
                sx={{ color: 'lighterText', lineHeight: '12px' }}
              >
                <Trans>Language</Trans>
              </Label>
              <Text variant="small" sx={{ lineHeight: '16px' }}>
                {site.name}
              </Text>
            </Box>
            <ChevronDownIcon
              sx={{ marginLeft: 1, color: 'lighterText' }}
              size="small"
            />
          </Flex>
        </MenuButton>
        <MenuList>
          {otherSites.map((site) => (
            <MenuLink key={site.name} href={site.url}>
              {site.name}
            </MenuLink>
          ))}
        </MenuList>
      </Menu>
    );
  return null;
}

const query = graphql`
  {
    collection: event {
      collectionId: eventId
    }
    allSanitySite {
      nodes {
        collectionId
        name
        sites {
          name
          url
        }
      }
    }
  }
`;
